.TableWrapper {
  background: white;
  padding: 0.5em;
  box-sizing: border-box;
  overflow-x: auto;
  max-width: 100%;
  th,
  td {
    padding: 0.5em !important;
  }
  tr:nth-child(even) {
    background: #eee;
  }
}

.SearchBox {
  margin: 0.5em 0 0.5em 0;
  border-width: 0.5px;
}
