@import "../../global.scss";
.DashboardLayout {
  display: flex;
  width: 100%;
  height: 100%;
}
.Sidebar {
  height: 100%;
  width: 9%;
  max-width: 8rem;
  background: $sidebg;
  min-width: 80px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.SidebarButton {
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
  img {
    height: 36px;
    width: 108px;
  }
}

.CollapseIcon {
  color: $text;
  font-size: 1.8rem;
}

.NavBox {
  border-top: 1px solid #13151d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.NewReportBox {
  height: 42px;
  width: 100%;
}

.SideFooter {
  background: #3b4150;
  padding: 13px 10px;
  box-sizing: border-box;
  a {
    font-size: 12px;
    font-weight: 600;
    color: #9b9ea5;
    display: flex;
    justify-content: center;
    text-decoration: none;
    span {
      padding-bottom: 3px;
      border-bottom: 1px solid #9b9ea5;
    }
  }
}

.CopyRight {
  color: #767a84;
  font-size: 10px;
  text-align: center;
  padding-top: 10px;
  line-height: 1.5;
}

.Header {
  position: sticky;
  background: #fff;
  display: flex;
  padding: 2rem;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 2.2rem;
    margin: 0;
  }
}

.FilterBar {
  height: 60px;
  background: #d8e0e5;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 2rem;
}

.ContentFrame {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.RouterFrame {
  overflow-y: scroll;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 2rem;
  flex: 1;
  position: relative;
  padding: 2rem;
  grid-auto-rows: min-content;
}

.AnalysisFrame {
  box-sizing: border-box;
}

.Back {
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;
  &.Hide {
    display: none;
  }
}

.Breadcrumb {
  font-size: 90%;
  min-height: 30px;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  &.Hide {
    display: none;
  }
}

.Base {
  span {
    padding-left: 1rem;
  }
}

.Dynamic {
  padding-left: 5px;
}

@media (min-width: 768px) {
  .Sidebar {
    min-width: 200px;
    max-width: 200px;
  }
}
