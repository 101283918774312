@import '../../global.scss';

.UserCard {
    padding: 2rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UserCircle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: $accent;
    text-align: center;
    margin-bottom: 1rem;
    label {
        line-height: 56px;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
    }
}

.Name {
    color: $accent-text;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: .3rem;
}

.Rank {
    font-weight: 500;
    color: $accent-text;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 1.4rem;
}

.Department {
    color: $text;
    margin-top: 0;
    font-weight: 500;
    font-size: 12px;
}