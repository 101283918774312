.AnalysisBar {
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  h2 {
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1.5rem;
  }
}

.AnalysesList {
  list-style: none;
  font-size: 1.2rem;
  margin: 0;
  padding: 0 2rem 2rem 2rem;
  li {
    margin-bottom: 1.25rem;
  }
}

.AnaysesLink {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}
