.EventBox {
  box-sizing: border-box;
  overflow-x: auto;
  max-width: 100%;
}

.EventContent {
  background: #fff;
  padding: 2rem;
}

.PaginationBox {
  text-align: right;
  margin-top: 1em;
}
