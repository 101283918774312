.EventLink {
  background: #fff;
  box-sizing: border-box;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
}

.Info {
  padding: 1rem;
  background-color: #f4f4f4;
  width: 100%;
}

.Type {
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
}

.Total {
  font-weight: 700;
  font-size: 5rem;
  text-align: center;
}
