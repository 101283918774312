@import "../../global.scss";

.TableBox {
  background: #fff;
  padding: 1.5rem;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2rem;
  h2 {
    padding-bottom: 1rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    &.Failed {
      background-color: rgb(255, 0, 0);
      color: #fff;
      border-radius: 0.25rem;
      box-sizing: border-box;
      padding: 1rem;
    }
  }
}

.Loader {
  height: 25px;
  display: flex;
  border-radius: 0.25rem;
  background-color: $sidebg;
  color: #fff;
  font-size: 1.2rem;
  align-items: center;
  padding: 0 1rem;
}

.SpinnerBox {
  color: rgb(143, 204, 253);
  font-size: 1.2rem;
  padding-left: 1rem;
}
