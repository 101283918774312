.ChartBox {
  background: #fff;
  box-sizing: border-box;
  padding: 1.5rem;
  h2 {
    padding-bottom: 1rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1.5rem;
  }
}

.ChartFrame {
  width: 100%;
  height: calc(300px + 2rem);
  background: #f4f4f4;
}

.EventBox {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
}
