.PeerBox {
  box-sizing: border-box;
  overflow-x: auto;
  max-width: 100%;
}

.peerContent {
  background: #fff;
  padding: 2rem;
}
