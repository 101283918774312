@import './global.scss';

html, body, #root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 65%;
  background: $mainbg;
}

.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: 'Lato', Arial;
}

