@import '../../global.scss';

.Navigation {
    border-top: 1px solid #1E222E;
    list-style: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.AdminNavigation {
    box-sizing: border-box;
    list-style: none;
    margin: none;
    padding: 1rem;
}

.Icon {
    margin-left: 20px;
}

.NavigationLink {
    color: #767A84;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    height: 42px;
    box-sizing: border-box;
    border-left: 8px solid transparent;
    width: 100%;
    padding: 0;
    margin: 0;
    transition: 400ms;
    &.Active {
        border-color: #fff;
        color: #fff;
    }
    &:hover {
        color: #fff;
    }
    span {
        padding-left: 15px;
    }
}

.StatsLink {
    padding: 1rem;
    color: $text;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 1.2rem;
    display: flex;
    background-color: #242a38;
    border-radius: .3rem;
    margin-bottom: .5rem;
    transition: all 400ms ease-out;
    &:hover {
        color: #fff;
    }
    span {
        padding-left: 1rem;
    }
}