.TableBox {
    background: #fff;
    padding: 1.5rem;
    box-sizing: border-box;
    width: 100%;
    h2 {
        padding-bottom: 1rem;
        font-size: 1.6rem;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 1.5rem;
    }
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}